<template>
    <div class="d-flex align-items-center justify-content-center" style="height: calc(100vh - 44px)">
        <div class="row">
            <input class="form-control mr-1" type="number" v-model="idutilisateur">
        </div>
        <div class="row">
            <button class="form-control btn btn-success" @click="impersonate()">Valider</button>
        </div>
    </div>
</template>

<script>

import authenticationService from "@/services/authenticationService";
import store from "@/store";
import router from "@/router";

export default {
    name: 'login',
    data() {
        return {
            idutilisateur: null
        }
    },
    mounted() {
    },
    methods: {
        impersonate() {
            authenticationService
                .impersonate(this.idutilisateur)
                .then(res => {
                    if (res.data.data) {
                        store.commit('UPDATE_USER', res.data.data)
                        router.push({name: 'home'})
                    } else {
                        this.$bvToast.toast(`Erreur`, {
                            title: 'Erreur 3002',
                            autoHideDelay: 5000,
                            variant: 'danger'
                        })
                    }
                })
                .catch(() => {
                    this.$bvToast.toast(`Erreur`, {
                        title: 'Erreur 3003',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                });
        }
    }
}
</script>

<style scoped>

</style>
